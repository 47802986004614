import React, { Fragment, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import _ from 'lodash';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Sidebar from '../../components/Sidebar';
import menu from '../../routes/menu';
import { setPageTitle } from '../../redux';
import application from '../../config/application';
import { getCashStatus2 } from './api';

const mapBreadcrumbs = () => {
  const breadcrumb = {};

  _.map(menu, (item) => {
    if (item.breadcrumb) {
      _.assign(breadcrumb, item.breadcrumb);
    }

    if (item.subMenu) {
      _.map(item.subMenu, (subItem) => _.assign(breadcrumb, subItem.breadcrumb)
      );
    }
  });

  return breadcrumb;
};

const Layout = (props) => {
  const { children } = props;
  const [breadrumbs] = useState(mapBreadcrumbs());
  const [title, setTitle] = useState('');
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    const { pathname } = location;
    const to = pathname.replace('/app', '');

    setTitle(`${application.name} - ${breadrumbs[to]}`);
    dispatch(setPageTitle(breadrumbs[to]));
  }, [breadrumbs, dispatch, location, title]);

  useEffect(() => {
    getCashStatus2(localStorage.getItem('office_id')).then((res) => {
      const response = res?.data || res?.response?.data;
      if (res?.status === 200) {
        localStorage.setItem('cashStatus', 'Terbuka');
      } else {
        localStorage.setItem('cashStatus', 'Tertutup');
      }
      localStorage.setItem('balance', response?.balance || 0);
    });
  }, [location]);

  return (
    <Fragment>
      <Sidebar>
        <Helmet>
          <meta charSet="utf-8" />
          <title>{title}</title>
        </Helmet>
        { children }
      </Sidebar>
    </Fragment>
  );
};

export default Layout;
