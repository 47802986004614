/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable semi */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom'
import Typography from '@material-ui/core/Typography';
import CardContent from '@material-ui/core/CardContent';
import Card from '@material-ui/core/Card';
import Icon from '../../../../components/icon';
import useStyles from './login-jss.js';
import LoginForm from './LoginForm';
import authApi from '../../../../services/authApi';
import env from '../../../../config/env';
import AlertMessage from '../../../../components/AlertMessage';
const Login = () => {
  const history = useHistory()
  const classes = useStyles();
  const initialValues = { username: '', password: '', grant_type: 'password' };
  const [error, setError] = useState({ message: '', type: 'error' });
  const [isError, setIsError] = useState(false);
  useEffect(() => {
    if (localStorage.getItem('token')) history.replace('/app/home/dashboard')
  }, [])
  const handleSubmit = async (value) => {
    try {
      const { status, data } = await authApi.post(`${env.authApi}/oauth/token?scope=read write`, value);
      if (status === 200) {
        const {
          access_token,
          refresh_token,
          user,
          user: { user_office, user_role, photo }
        } = data;
        const officeType = user_office.officeable_type
        localStorage.setItem('token', access_token);
        localStorage.setItem('refresh_token', refresh_token);
        localStorage.setItem('name', user.first_name + ' ' + user.last_name);
        localStorage.setItem('id', user._id.$oid);
        localStorage.setItem('position', user.position);
        localStorage.setItem('user_role', JSON.stringify(user.user_role.role_modules));
        localStorage.setItem('role_name', user.user_role.role_description);
        localStorage.setItem('office_id', user_office.officeable_id.$oid);
        localStorage.setItem('office_type', officeType);
        localStorage.setItem('roleId', user_role.role_id.$oid);
        localStorage.setItem('roleName', user_role.role_name);
        localStorage.setItem('photo', photo);
        if (officeType === 'head') {
          setIsError(true);
          setError({ message: 'Maaf akun tidak ditemukan/ tidak ada, silahkan hubungi administrator', type: 'error' });
          localStorage.clear();
        } else window.location = '/app/home/dashboard';
      }
    } catch (err) {
      const { response: { data: { message } } } = err;
      let errMsg = '';
      if (message === 'user locked') {
        errMsg = 'Maaf akun anda diblokir sementara selama 30 menit karena 3x salah memasukkan kata sandi. Silahkan coba lagi setelah 30 menit.';
      } else if (message === 'email / nik not registered') {
        errMsg = 'Email / NIK tidak terdaftar atau Kata sandi yang anda masukan salah. Silahkan coba lagi.';
      } else if (message === 'password salah') {
        errMsg = 'Kata sandi salah.';
      } else {
        errMsg = message;
      }
      setIsError(true);
      setError({ message: errMsg, type: 'error' });
    }
  };
  const triggerSetError = (value) => {
    setIsError(value);
  };
  return (
    <div
      className={classes.mainContainer}
      style={{
        background: 'radial-gradient(circle, rgba(79,221,105,1) 0%, rgba(30,155,53,1) 100%)',
      }}
    >
      <Card className={classes.card}>
        <CardContent className={classes.cardContent}>
          <img src={Icon.logo} className={classes.img} alt="logo" />
          <Typography component="h1" variant="h5">
            Masuk
          </Typography>
          <AlertMessage
            open={isError}
            severity={error.type}
            message={error.message}
            triggerSetError={triggerSetError}
          />
          <LoginForm initialValues={initialValues} onSubmit={handleSubmit} />
        </CardContent>
      </Card>
    </div>
  );
};
export default Login;
