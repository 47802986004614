/* eslint-disable camelcase */
import React, { Fragment } from 'react';
import { Switch, Route } from 'react-router-dom';
import Layout from '../container/Layout';
import PrivateRoute from '../components/PrivateRoute';
import NotificationGateway from '../redux/notification/NotificationGateway';
import {
  DashboardPage,
  CustomerIndividual,
  SalesAgent,
  Elektronik,
  TransactionIndividual,
  Request,
  Journal_All,
  TranscationReport,
  Cashflow,
  CashDisbursements,
  CashJournal,
  MiscellaneousExpense,
  accessDenied,
  AllNotification,
  EntryJournalForm,
  MokerHistory,
  WorkcapApproval,
  DeviationApproval,
  Profile,
  ChangePassword,
  TransactionStock,
  WorkcapMutation,
  Accrual,
  OneObligor,
  NonTransactional
} from '../container/Pages/asyncpages';
import application from '../config/application';
import NotFound from './NotFound';

const SecureRoutes = ({ history }) => {
  const securePath = application.privatePath;
  return (
    <Fragment>
      <NotificationGateway>
        <Layout history={history}>
          <Switch>

            {/* Dashboard */}

            <PrivateRoute exact path={`${securePath}`} component={DashboardPage} />
            <PrivateRoute path={`${securePath}/home/dashboard`} component={DashboardPage} />

            {/* Data Nasabah */}

            <PrivateRoute path={`${securePath}/customer/individual`} component={CustomerIndividual} />
            <PrivateRoute path={`${securePath}/customer/sales-agent`} component={SalesAgent} />
            <PrivateRoute path={`${securePath}/customer/elektronik`} component={Elektronik} />

            {/* Manajemen Transaksi */}

            <PrivateRoute exact path={`${securePath}/transaction/individual`} component={TransactionIndividual} />
            <PrivateRoute exact path={`${securePath}/transaction/deviation`} component={DeviationApproval} />
            <PrivateRoute exact path={`${securePath}/transaction/stock`} component={TransactionStock} />
            <PrivateRoute exact path={`${securePath}/transaction/oneobligor`} component={OneObligor} />
            <PrivateRoute exact path={`${securePath}/transaction/non-transactional`} component={NonTransactional} />

            {/* Finansial Manajemen */}

            <PrivateRoute path={`${securePath}/financial-management/request`} component={Request} />
            <PrivateRoute path={`${securePath}/financial-management/miscellaneous-expense`} component={MiscellaneousExpense} />

            {/* Jurnal */}

            <PrivateRoute path={`${securePath}/journal/all`} component={Journal_All} />
            <PrivateRoute path={`${securePath}/journal/accrual`} component={Accrual} />
            <PrivateRoute path={`${securePath}/journal/add`} component={EntryJournalForm} />

            {/* Report */}

            <PrivateRoute path={`${securePath}/report/all`} component={TranscationReport} />

            {/* Laporan Transaksi */}

            <PrivateRoute path={`${securePath}/cashflow/all`} component={Cashflow} />
            <PrivateRoute path={`${securePath}/cashflow/cash-disbursements`} component={CashDisbursements} />
            <PrivateRoute path={`${securePath}/cashflow/cash-journal`} component={CashJournal} />
            <PrivateRoute path={`${securePath}/cashflow/moker`} component={MokerHistory} />

            {/* Modal Kerja */}

            <PrivateRoute path={`${securePath}/workcap/moker`} component={MokerHistory} />
            <PrivateRoute path={`${securePath}/workcap/approval`} component={WorkcapApproval} />
            <PrivateRoute path={`${securePath}/workcap/workcap-mutation`} component={WorkcapMutation} />

            {/* Setting */}

            <Route path={`${securePath}/profile`} component={Profile} />
            <Route path={`${securePath}/change-password`} component={ChangePassword} />

            {/* etc */}

            <Route path={`${securePath}/page/access-denied`} component={accessDenied} />
            <Route path={`${securePath}/notification`} component={AllNotification} />
            <Route path={`${securePath}/transaction/individual/:id/:type`} component={TransactionIndividual} />
            <Route component={NotFound} />

          </Switch>
        </Layout>
      </NotificationGateway>
    </Fragment>
  );
};

export default SecureRoutes;
